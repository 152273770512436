<template>
  <div :style="{ width: '100%', display: 'flex' }">
    <ValidationProvider
      :style="{ width, 'min-width': '200px' }"
      v-slot="{ errors }"
      :rules="rules"
      :name="label"
      :vid="name"
    >
      <v-select
        class="select-field"
        v-model="localValue"
        :placeholder="placeholder"
        xs
        filled
        single-line
        height="48"
        append-icon="mdi-chevron-down"
        :error-messages="errors[0]"
        :items="mergedOptions"
        :item-text="textKey"
        :item-value="valueKey"
        :multiple="multiple"
        :name="name"
        @input="checkLimit"
        no-data-text="なし"
        :hide-details="hideDetails"
        :background-color="backgroundColor"
      ></v-select>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    options: {
      type: Array,
      required: true
    },
    textKey: {
      type: String,
      default: "text"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    multiple: {
      type: Boolean,
      default: false
    },
    rules: {
      type: String
    },
    label: {
      type: String
    },
    name: {
      type: String
    },
    limit: {
      type: Number,
      default: null
    },
    width: {
      type: String,
      default: "200px"
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    useClear: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "---"
    },
    backgroundColor: {
      type: String,
      default: "#f3f5f6"
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    mergedOptions() {
      if (this.useClear) {
        return [
          ...[
            {
              [this.textKey]: "---",
              [this.valueKey]: ""
            }
          ],
          ...this.options
        ];
      } else {
        return this.options;
      }
    }
  },
  methods: {
    checkLimit(selections) {
      if (this.multiple && this.limit > 1 && selections.length > this.limit) {
        selections.splice(this.limit);
      }
    }
  }
};
</script>

<style lang="scss">
.select-field {
  .v-input__slot {
    min-height: 48px !important;
    &:before {
      border-color: transparent !important;
    }
  }

  .v-input__append-inner {
    margin-top: 16px;
  }
}
</style>
