<template>
  <div v-if="show">
    <div class="ma-0 red--text text--darken-1" text-color="red" outlined x-small>※</div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style module lang="scss"></style>
