<template>
  <div :style="{ width: '100%', display: 'flex' }">
    <ValidationProvider
      :style="{ width, 'min-width': '160px' }"
      v-slot="{ errors }"
      :rules="rules"
      :name="label"
      :vid="name"
    >
      <v-text-field
        class="text-field rounded-0"
        v-model="localValue"
        single-line
        height="48"
        :error-messages="errors[0]"
        clearable
        solo
        flat
        :name="name"
        :hide-details="hideDetails"
        :background-color="backgroundColor"
        :placeholder="placeholder"
      />
    </ValidationProvider>
    <div v-if="remarks" :class="$style['remarks']">{{ remarks }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    rules: {
      type: String
    },
    label: {
      type: String
    },
    name: {
      type: String
    },
    width: {
      type: String,
      default: "200px"
    },
    remarks: {
      type: String,
      default: ""
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    backgroundColor: {
      type: String,
      default: "#fff"
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style module lang="scss">
.remarks {
  font-size: 12px;
  margin-top: 8px;
  margin-left: 24px;
  &:before {
    content: "※";
    margin-right: 4px;
  }
}
</style>

<style lang="scss">
.text-field {
  .v-input__slot {
    min-height: 48px !important;
    &:before {
      border-color: transparent !important;
    }
  }
}
</style>
