<template>
  <v-menu
    v-model="menu"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <ValidationProvider
        v-slot="{ errors }"
        :rules="rules"
        :name="label"
        :vid="name"
      >
        <v-text-field
          v-model="localValue"
          v-on="on"
          clearable
          filled
          readonly
          :error-messages="errors[0]"
          :name="name"
        ></v-text-field>
      </ValidationProvider>
    </template>
    <v-date-picker
      v-model="localValue"
      no-title
      @input="menu2 = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {},
    rules: {
      type: String
    },
    label: {
      type: String
    },
    name: {
      type: String
    }
  },
  data() {
    return {
      menu: false
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style module lang="scss"></style>
