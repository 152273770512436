<template>
  <v-subheader class="d-flex" :class="[verticalAlign]">
    <label :style="{ fontSize: `${size}px` }" class="mr-3 font-weight-bold">{{
      label
    }}</label>
    <RequiredTag :show="show" />
  </v-subheader>
</template>

<script>
import RequiredTag from "./tag/required";
export default {
  components: {
    RequiredTag
  },
  props: {
    label: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 14
    },
    show: {
      type: Boolean,
      default: false
    },
    verticalAlign: {
      type: String,
      default: "align-start"
    }
  }
};
</script>
